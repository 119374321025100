// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".sJnLKzMucCcifyvvTo6g .mTFY_KqZAlZQm9riMWHg {\n  border: 1px solid black;\n  padding: 6px 12px;\n}\n.sJnLKzMucCcifyvvTo6g .mTFY_KqZAlZQm9riMWHg > * {\n  margin-right: 12px;\n}\n.sJnLKzMucCcifyvvTo6g .mTFY_KqZAlZQm9riMWHg > *:last-child {\n  margin-right: 0;\n}\n.sJnLKzMucCcifyvvTo6g .mTFY_KqZAlZQm9riMWHg > *.ftw0gYDlN37yyGj7uCzi {\n  margin-left: -12px;\n  margin-right: 4px;\n}\n.sJnLKzMucCcifyvvTo6g .VCDz7uDuVojbY_iIB5gN {\n  margin: 0 12px;\n}\n.sJnLKzMucCcifyvvTo6g .uc68kZy7K2bTq3gtFrZx {\n  margin-left: 12px;\n}\n", "",{"version":3,"sources":["webpack://./src/features/d10/RollResult.module.less"],"names":[],"mappings":"AAEA;EAEI,uBAAA;EACA,iBAAA;AAFJ;AAII;EACE,kBAAA;AAFN;AAIM;EACE,eAAA;AAFR;AAKM;EACE,kBAAA;EACA,iBAAA;AAHR;AAXA;EAoBI,cAAA;AANJ;AAdA;EAwBI,iBAAA;AAPJ","sourcesContent":["@import (reference) \"common.less\";\n\n.result {\n  .dice {\n    border: 1px solid black;\n    padding: 6px 12px;\n\n    & > * {\n      margin-right: 12px;\n\n      &:last-child {\n        margin-right: 0;\n      }\n\n      &.reroll-arrow {\n        margin-left: -12px;\n        margin-right: 4px;\n      }\n    }\n  }\n\n  .separator {\n    margin: 0 12px;\n  }\n\n  .modifier {\n    margin-left: 12px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"result": "sJnLKzMucCcifyvvTo6g",
	"dice": "mTFY_KqZAlZQm9riMWHg",
	"reroll-arrow": "ftw0gYDlN37yyGj7uCzi",
	"separator": "VCDz7uDuVojbY_iIB5gN",
	"modifier": "uc68kZy7K2bTq3gtFrZx"
};
export default ___CSS_LOADER_EXPORT___;
