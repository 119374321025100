// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".T4Dk1heofNchgsoXEeTg > * {\n  margin: 2px;\n}\n.P1zQTaAlHFWwNuakzrBt {\n  margin-top: 24px;\n}\n.P1zQTaAlHFWwNuakzrBt .ubtFNndDDhGcxylMUZHb,\n.P1zQTaAlHFWwNuakzrBt .jwWUFIS1SbNc0BYAFLzg {\n  display: flex;\n  flex-wrap: wrap;\n}\n.P1zQTaAlHFWwNuakzrBt .ubtFNndDDhGcxylMUZHb > *,\n.P1zQTaAlHFWwNuakzrBt .jwWUFIS1SbNc0BYAFLzg > * {\n  padding: 12px;\n}\n.P1zQTaAlHFWwNuakzrBt .ubtFNndDDhGcxylMUZHb {\n  justify-content: space-around;\n}\n.P1zQTaAlHFWwNuakzrBt .jwWUFIS1SbNc0BYAFLzg {\n  justify-content: center;\n}\n", "",{"version":3,"sources":["webpack://./src/features/sw/Result.module.less"],"names":[],"mappings":"AACE;EACE,WAAA;AAAJ;AAIA;EACE,gBAAA;AAFF;AACA;;EAKI,aAAA;EACA,eAAA;AAFJ;AAII;;EACE,aAAA;AADN;AARA;EAcI,6BAAA;AAHJ;AAXA;EAkBI,uBAAA;AAJJ","sourcesContent":[".dice {\n  & > * {\n    margin: 2px;\n  }\n}\n\n.summary {\n  margin-top: 24px;\n\n  .additions,\n  .force {\n    display: flex;\n    flex-wrap: wrap;\n\n    & > * {\n      padding: 12px;\n    }\n  }\n\n  .additions {\n    justify-content: space-around;\n  }\n\n  .force {\n    justify-content: center;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dice": "T4Dk1heofNchgsoXEeTg",
	"summary": "P1zQTaAlHFWwNuakzrBt",
	"additions": "ubtFNndDDhGcxylMUZHb",
	"force": "jwWUFIS1SbNc0BYAFLzg"
};
export default ___CSS_LOADER_EXPORT___;
