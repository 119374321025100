// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".jo9dBlDT1FXdHyY8RX9h {\n  display: flex;\n  justify-content: space-evenly;\n  flex-direction: column;\n  align-items: center;\n}\n.jo9dBlDT1FXdHyY8RX9h > * {\n  margin-bottom: 24px;\n}\n", "",{"version":3,"sources":["webpack://./src/features/heritage/SummaryList.module.less"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,6BAAA;EACA,sBAAA;EACA,mBAAA;AACF;AACE;EACE,mBAAA;AACJ","sourcesContent":[".container {\n  display: flex;\n  justify-content: space-evenly;\n  flex-direction: column;\n  align-items: center;\n\n  & > * {\n    margin-bottom: 24px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "jo9dBlDT1FXdHyY8RX9h"
};
export default ___CSS_LOADER_EXPORT___;
