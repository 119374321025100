// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".e4Be07q5NdRS007B5Ie1 svg {\n  margin-left: 2px;\n}\n", "",{"version":3,"sources":["webpack://./src/features/navigation/ExternalLink.module.less"],"names":[],"mappings":"AAAA;EAEI,gBAAA;AAAJ","sourcesContent":[".link {\n  svg {\n    margin-left: 2px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"link": "e4Be07q5NdRS007B5Ie1"
};
export default ___CSS_LOADER_EXPORT___;
