// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".QEkxd6BgcAqc4fiXHAuR {\n  margin-bottom: 1em;\n  text-align: center;\n}\n.QEkxd6BgcAqc4fiXHAuR p,\n.QEkxd6BgcAqc4fiXHAuR ul {\n  margin-bottom: 0;\n}\n.QEkxd6BgcAqc4fiXHAuR ul {\n  display: flex;\n  justify-content: center;\n  flex-wrap: wrap;\n}\n.QEkxd6BgcAqc4fiXHAuR ul li {\n  padding: 0;\n  margin-right: 12px;\n  flex-shrink: 0;\n}\n", "",{"version":3,"sources":["webpack://./src/features/dnd/Roller.module.less"],"names":[],"mappings":"AAEA;EACE,kBAAA;EACA,kBAAA;AADF;AADA;;EAMI,gBAAA;AADJ;AALA;EAUI,aAAA;EACA,uBAAA;EACA,eAAA;AAFJ;AAVA;EAeM,UAAA;EACA,kBAAA;EACA,cAAA;AAFN","sourcesContent":["@import (reference) \"common.less\";\n\n.syntax {\n  margin-bottom: 1em;\n  text-align: center;\n\n  p,\n  ul {\n    margin-bottom: 0;\n  }\n\n  ul {\n    display: flex;\n    justify-content: center;\n    flex-wrap: wrap;\n\n    li {\n      padding: 0;\n      margin-right: 12px;\n      flex-shrink: 0;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"syntax": "QEkxd6BgcAqc4fiXHAuR"
};
export default ___CSS_LOADER_EXPORT___;
