// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".VafmET3v8LhQjDgQXMa0 .luBVeGfz6p0FneB7MlUd {\n  display: flex;\n  flex-wrap: wrap;\n}\n.VafmET3v8LhQjDgQXMa0 .luBVeGfz6p0FneB7MlUd > * {\n  margin-right: 12px;\n  margin-bottom: 12px;\n}\n", "",{"version":3,"sources":["webpack://./src/features/roller/form/DynamicDiceSelector.module.less"],"names":[],"mappings":"AAAA;EAEI,aAAA;EACA,eAAA;AAAJ;AAEI;EACE,kBAAA;EACA,mBAAA;AAAN","sourcesContent":[".container {\n  .selects {\n    display: flex;\n    flex-wrap: wrap;\n\n    & > * {\n      margin-right: 12px;\n      margin-bottom: 12px;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "VafmET3v8LhQjDgQXMa0",
	"selects": "luBVeGfz6p0FneB7MlUd"
};
export default ___CSS_LOADER_EXPORT___;
