// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".PqSjUomzIwr0Sb7AGRMm {\n  display: flex;\n  justify-content: right;\n}\n.PqSjUomzIwr0Sb7AGRMm > * {\n  margin-right: 24px;\n}\n.PqSjUomzIwr0Sb7AGRMm > *:last-child {\n  margin-right: 0;\n}\n.uNsKbuqGvgskfVnX3X3y {\n  text-align: center;\n  font-size: 48px;\n  margin-top: -24px;\n}\n.fF7Z5VunvoBXz3H9GlsC {\n  display: flex;\n  flex-wrap: wrap;\n  justify-content: center;\n}\n.fF7Z5VunvoBXz3H9GlsC > * {\n  margin: 0 6px;\n  margin-bottom: 16px;\n}\n", "",{"version":3,"sources":["webpack://./src/features/roller/reroll/Alter.module.less"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;AACF;AACE;EACE,kBAAA;AACJ;AACI;EACE,eAAA;AACN;AAIA;EACE,kBAAA;EACA,eAAA;EACA,iBAAA;AAFF;AAKA;EACE,aAAA;EACA,eAAA;EACA,uBAAA;AAHF;AAKE;EACE,aAAA;EACA,mBAAA;AAHJ","sourcesContent":[".buttons {\n  display: flex;\n  justify-content: right;\n\n  & > * {\n    margin-right: 24px;\n\n    &:last-child {\n      margin-right: 0;\n    }\n  }\n}\n\n.arrow {\n  text-align: center;\n  font-size: 48px;\n  margin-top: -24px;\n}\n\n.altered-dice {\n  display: flex;\n  flex-wrap: wrap;\n  justify-content: center;\n\n  & > * {\n    margin: 0 6px;\n    margin-bottom: 16px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"buttons": "PqSjUomzIwr0Sb7AGRMm",
	"arrow": "uNsKbuqGvgskfVnX3X3y",
	"altered-dice": "fF7Z5VunvoBXz3H9GlsC"
};
export default ___CSS_LOADER_EXPORT___;
