// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".B3UVP_kXDPNfKO5jU2MH {\n  min-height: 100vh;\n  background-color: #f5f5f5;\n  /* Allow to strech direct children height with instructions like flex: 1 */\n}\n.B3UVP_kXDPNfKO5jU2MH .ant-layout-content {\n  display: flex;\n  flex-direction: column;\n}\n.Hl26XY0n1XPambLx5dxx {\n  text-align: center;\n}\n@media (max-width: 576px) {\n  .B3UVP_kXDPNfKO5jU2MH > header {\n    padding: 0;\n  }\n}\n", "",{"version":3,"sources":["webpack://./src/features/navigation/Layout.module.less"],"names":[],"mappings":"AAEA;EACE,iBAAA;EACA,yBAAA;EADA,0EAA0E;AAC5E;AAFA;EAMI,aAAA;EACA,sBAAA;AADJ;AAKA;EACE,kBAAA;AAHF;AAMA;EACE;IACE,UAAA;EAJF;AACF","sourcesContent":["@import (reference) \"common.less\";\n\n.layout {\n  min-height: 100vh;\n  background-color: @background-color-base;\n\n  /* Allow to strech direct children height with instructions like flex: 1 */\n  :global(.ant-layout-content) {\n    display: flex;\n    flex-direction: column;\n  }\n}\n\n.footer {\n  text-align: center;\n}\n\n@media (max-width: @screen-sm) {\n  .layout > header {\n    padding: 0;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"layout": "B3UVP_kXDPNfKO5jU2MH",
	"footer": "Hl26XY0n1XPambLx5dxx"
};
export default ___CSS_LOADER_EXPORT___;
