// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._JrCg_66YiWAvv_NcyIS .APONqm7alEuisd7vvay3 {\n  border: 1px solid black;\n  padding: 4px;\n  border-radius: 4px;\n}\n._JrCg_66YiWAvv_NcyIS .TpncW758HNVYu6OD2Bj9 {\n  vertical-align: sub;\n  font-size: 80%;\n  margin-left: 2px;\n}\n", "",{"version":3,"sources":["webpack://./src/features/dnd/TextResult.module.less"],"names":[],"mappings":"AAEA;EAEI,uBAAA;EACA,YAAA;EACA,kBAAA;AAFJ;AAFA;EAOI,mBAAA;EACA,cAAA;EACA,gBAAA;AAFJ","sourcesContent":["@import (reference) \"common.less\";\n\n.dice-group {\n  .values {\n    border: 1px solid black;\n    padding: 4px;\n    border-radius: 4px;\n  }\n  .type {\n    vertical-align: sub;\n    font-size: 80%;\n    margin-left: 2px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dice-group": "_JrCg_66YiWAvv_NcyIS",
	"values": "APONqm7alEuisd7vvay3",
	"type": "TpncW758HNVYu6OD2Bj9"
};
export default ___CSS_LOADER_EXPORT___;
