// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".xfY0CivDIPYiweVYuDOI .p_vXod7MQ2IlYoIfQTqI {\n  display: flex;\n  justify-content: space-between;\n}\n.xfY0CivDIPYiweVYuDOI .p_vXod7MQ2IlYoIfQTqI label {\n  min-width: 82px;\n}\n.xfY0CivDIPYiweVYuDOI .IJz_wSiTfHic7K9dfhVM {\n  display: flex;\n  justify-content: center;\n  margin-bottom: 24px;\n}\n", "",{"version":3,"sources":["webpack://./src/features/sw/Roller.module.less"],"names":[],"mappings":"AAAA;EAEI,aAAA;EACA,8BAAA;AAAJ;AAHA;EAMM,eAAA;AAAN;AANA;EAWI,aAAA;EACA,uBAAA;EACA,mBAAA;AAFJ","sourcesContent":[".form {\n  .line {\n    display: flex;\n    justify-content: space-between;\n\n    label {\n      min-width: 82px;\n    }\n  }\n\n  .center {\n    display: flex;\n    justify-content: center;\n    margin-bottom: 24px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"form": "xfY0CivDIPYiweVYuDOI",
	"line": "p_vXod7MQ2IlYoIfQTqI",
	"center": "IJz_wSiTfHic7K9dfhVM"
};
export default ___CSS_LOADER_EXPORT___;
