// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".pXxB3k6yD7nNWeiMuWNL {\n  background-color: #fafafa;\n  padding: 16px;\n  display: flex;\n  justify-content: flex-end;\n}\n", "",{"version":3,"sources":["webpack://./src/Pagination.module.less"],"names":[],"mappings":"AAEA;EACE,yBAAA;EACA,aAAA;EACA,aAAA;EACA,yBAAA;AADF","sourcesContent":["@import (reference) \"common.less\";\n\n.layout {\n  background-color: @background-color-light;\n  padding: 16px;\n  display: flex;\n  justify-content: flex-end;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"layout": "pXxB3k6yD7nNWeiMuWNL"
};
export default ___CSS_LOADER_EXPORT___;
