// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".KqBDgld1zX4sBe5jx0FU {\n  background-color: white;\n  border: 1px solid rgba(0, 0, 0, 0.25);\n  padding: 12px;\n}\n.KqBDgld1zX4sBe5jx0FU > *:last-child {\n  margin-bottom: 0;\n}\n.KqBDgld1zX4sBe5jx0FU dl dt:before {\n  content: \"💮 \";\n}\n.KqBDgld1zX4sBe5jx0FU dl dd:last-of-type {\n  margin-bottom: 0;\n}\n", "",{"version":3,"sources":["webpack://./src/features/roller/glitter/ExplainOptions.module.less"],"names":[],"mappings":"AAEA;EACE,uBAAA;EACA,qCAAA;EACA,aAAA;AADF;AAGE;EACE,gBAAA;AADJ;AALA;EAWM,cAAA;AAHN;AARA;EAeM,gBAAA;AAJN","sourcesContent":["@import (reference) \"common.less\";\n\n.container {\n  background-color: white;\n  border: 1px solid fade(black, 25%);\n  padding: 12px;\n\n  & > *:last-child {\n    margin-bottom: 0;\n  }\n\n  dl {\n    dt:before {\n      content: \"💮 \";\n    }\n\n    dd:last-of-type {\n      margin-bottom: 0;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "KqBDgld1zX4sBe5jx0FU"
};
export default ___CSS_LOADER_EXPORT___;
