// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".zyZ8WzmMj6OgJefaFqew {\n  display: flex;\n  justify-content: right;\n}\n.zyZ8WzmMj6OgJefaFqew > * {\n  margin-right: 24px;\n}\n.zyZ8WzmMj6OgJefaFqew > *:last-child {\n  margin-right: 0;\n}\n", "",{"version":3,"sources":["webpack://./src/features/roller/reroll/Offering.module.less"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;AACF;AACE;EACE,kBAAA;AACJ;AAAI;EACE,eAAA;AAEN","sourcesContent":[".buttons {\n  display: flex;\n  justify-content: right;\n\n  & > * {\n    margin-right: 24px;\n    &:last-child {\n      margin-right: 0;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"buttons": "zyZ8WzmMj6OgJefaFqew"
};
export default ___CSS_LOADER_EXPORT___;
