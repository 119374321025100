// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Y7pi0FKkvLn4smNS1DVZ > label {\n  height: 100%;\n  padding-top: 4px;\n  padding-bottom: 4px;\n}\n.Y7pi0FKkvLn4smNS1DVZ img {\n  width: 45px;\n}\n.nQLnXLYyhmHbzZlXpNzo .ant-select-selector {\n  height: 100% !important;\n  padding-left: 0 !important;\n}\n", "",{"version":3,"sources":["webpack://./src/features/roller/SelectDieSide.module.less"],"names":[],"mappings":"AACE;EACE,YAAA;EACA,gBAAA;EACA,mBAAA;AAAJ;AAJA;EAQI,WAAA;AADJ;AAKA;EAEI,uBAAA;EACA,0BAAA;AAJJ","sourcesContent":[".group {\n  & > label {\n    height: 100%;\n    padding-top: 4px;\n    padding-bottom: 4px;\n  }\n\n  img {\n    width: 45px;\n  }\n}\n\n.select {\n  :global(.ant-select-selector) {\n    height: 100% !important;\n    padding-left: 0 !important;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"group": "Y7pi0FKkvLn4smNS1DVZ",
	"select": "nQLnXLYyhmHbzZlXpNzo"
};
export default ___CSS_LOADER_EXPORT___;
