// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".JKT2YUvhwoTwesEBTpK_ {\n  display: flex;\n  align-items: center;\n}\n.JKT2YUvhwoTwesEBTpK_ img {\n  height: 2em;\n}\n.vaj4_zMQC4MY3_ZPCRo7 img,\n.gK95m4kOTav2pWaUqbxA img {\n  margin: 0 4px;\n}\n", "",{"version":3,"sources":["webpack://./src/features/sw/LabeledDie.module.less"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,mBAAA;AACF;AAHA;EAKI,WAAA;AACJ;AAGA;;EAGI,aAAA;AAFJ","sourcesContent":[".container {\n  display: flex;\n  align-items: center;\n\n  img {\n    height: 2em;\n  }\n}\n\n.challenge,\n.proficiency {\n  img {\n    margin: 0 4px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "JKT2YUvhwoTwesEBTpK_",
	"challenge": "vaj4_zMQC4MY3_ZPCRo7",
	"proficiency": "gK95m4kOTav2pWaUqbxA"
};
export default ___CSS_LOADER_EXPORT___;
