// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ACdN3a4j8x7Mk3AV9v9v {\n  height: 1em;\n}\nsvg.ACdN3a4j8x7Mk3AV9v9v path {\n  fill: currentColor;\n}\n", "",{"version":3,"sources":["webpack://./src/features/display/Symbol.module.less"],"names":[],"mappings":"AAAA;EACE,WAAA;AACF;AAEA;EAEI,kBAAA;AADJ","sourcesContent":[".image {\n  height: 1em;\n}\n\nsvg.image {\n  path {\n    fill: currentColor;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"image": "ACdN3a4j8x7Mk3AV9v9v"
};
export default ___CSS_LOADER_EXPORT___;
