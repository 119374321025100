// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".K1wUI7MtX5JHDklLhBO0 {\n  margin-bottom: 24px;\n}\n", "",{"version":3,"sources":["webpack://./src/features/roller/Modifier.module.less"],"names":[],"mappings":"AAAA;EACE,mBAAA;AACF","sourcesContent":[".previous-rolls {\n  margin-bottom: 24px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"previous-rolls": "K1wUI7MtX5JHDklLhBO0"
};
export default ___CSS_LOADER_EXPORT___;
