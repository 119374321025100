// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".JmZAALqHyu986mytq6oN {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n.JmZAALqHyu986mytq6oN > span {\n  margin-right: 12px;\n}\n.qZXRM4dlTCYcML5bW29R {\n  background-color: white;\n  border: 1px solid rgba(0, 0, 0, 0.25);\n  margin: 12px 0;\n  padding: 12px;\n  text-align: center;\n}\n", "",{"version":3,"sources":["webpack://./src/features/cyberpunk/Roller.module.less"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,uBAAA;EACA,mBAAA;AACF;AACE;EACE,kBAAA;AACJ;AAGA;EACE,uBAAA;EACA,qCAAA;EACA,cAAA;EACA,aAAA;EACA,kBAAA;AADF","sourcesContent":[".formula {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n\n  & > span {\n    margin-right: 12px;\n  }\n}\n\n.explanation {\n  background-color: white;\n  border: 1px solid fade(black, 25%);\n  margin: 12px 0;\n  padding: 12px;\n  text-align: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"formula": "JmZAALqHyu986mytq6oN",
	"explanation": "qZXRM4dlTCYcML5bW29R"
};
export default ___CSS_LOADER_EXPORT___;
