export const distinctions = [
  "Paragon of Compassion (Water)",
  "Paragon of Courage (Fire)",
  "Paragon of Courtesy (Air)",
  "Paragon of Duty and Loyalty (Earth)",
  "Paragon of Honor (Void)",
  "Paragon of Righteousness (Void)",
  "Paragon of Sincerity (Void)",
  "Affect of Harmlessness (Air)",
  "Ally [Name] (Water)",
  "Ambidexterity (Air)",
  "Animal Trainer (Water)",
  "Ardent Leader (Fire)",
  "Benten's Blessing (Air)",
  "Bishamon's Blessing (Water)",
  "Blackmail on [Name] (Fire)",
  "Blessed Lineage (Void)",
  "Blissful Betrothal (Water)",
  "Blood of Osano-wo (Fire)",
  "Criminal Connections (Air)",
  "Daikoku's Blessing (Water)",
  "Dangerous Allure (Fire)",
  "Dashing Scar (Air)",
  "Dead Eyes (Earth)",
  "Ebisu's Blessing (Earth)",
  "Expert Tracker (Air)",
  "Famously Honest (Air)",
  "Famously Kind (Water)",
  "Famously Lucky (Void)",
  "Famously Neutral (Earth)",
  "Famously Reliable (Earth)",
  "Famously Successful (Fire)",
  "Famously Wealthy (Water)",
  "Flexibility (Water)",
  "Friend of the Brotherhood (Void)",
  "Friend of the Nezumi (Water)",
  "Fukurokujin's Blessing (Fire)",
  "Guiding Ancestor (Void)",
  "Hero of [Village] (Fire)",
  "Indomitable Will (Earth)",
  "Jurojin's Blessing (Void)",
  "Karmic Tie (Void)",
  "Keen Balance (Earth)",
  "Keen Hearing (Air)",
  "Keen Sight (Water)",
  "Keen Smell (Fire)",
  "Kisshoten's Blessing (Water)",
  "Kuge Lineage (Earth)",
  "Large Stature (Earth)",
  "Light Sleeper (Water)",
  "Lucky Hunter (Void)",
  "Portentous Birth (Fire)",
  "Precise Memory (Earth)",
  "Quick Reflexes (Fire)",
  "Scrappy (Fire)",
  "Scrounger (Water)",
  "Sea Legs (Water)",
  "Seasoned (Void)",
  "Sixth Sense (Void)",
  "Small Stature (Air)",
  "Spiritual Protection (Void)",
  "Subtle Observer (Air)",
  "Support of [One Group] (Water)",
  "Talented Herbalist (Earth)",
  "Traditional Adherent (Earth)",
  "Urban and Worldly (Void)",
  "Well Connected (Water)",
  "Well Connected in [City] (Water)",
];

export const passions = [
  "Ancestry (Earth)",
  "Animal Bond (Earth)",
  "Armament (Earth)",
  "Attention (Fire)",
  "Bathing (Water)",
  "Bonsai (Air)",
  "Brushwork (Air)",
  "City [Choose One] (Water)",
  "Creatures (Fire)",
  "Curiosity (Fire)",
  "Daredevil (Fire)",
  "Decorum (Water)",
  "Enlightenment (Void)",
  "Fashion (Fire)",
  "Festivals (Fire)",
  "Fortune-Telling (Void)",
  "Generosity (Water)",
  "Glorious Deeds (Fire)",
  "Gossip (Air)",
  "Gourmet (Water)",
  "History (Earth)",
  "Honest Work (Earth)",
  "Ikebana (Water)",
  "Kabuki (Air)",
  "Knotwork (Air)",
  "Kyudo (Void)",
  "Local Flare for [Region] (Earth)",
  "Military History (Earth)",
  "Navigator (Fire)",
  "Passion for [Foreign Performing Art Form] (Air)",
  "Playfulness (Air)",
  "Pot Stirrer (Fire)",
  "Provocation (Fire)",
  "Religious Study (Void)",
  "Researcher (Air)",
  "Rock Gardening (Void)",
  "Sake (Water)",
  "Secrets (Void)",
  "Stories (Earth)",
  "Tea (Void)",
  "Travel (Water)",
  "Wordplay (Air)",
];

export const adversities = [
  "Adopted Peasant (Fire)",
  "Allergy [Choose One] (Air)",
  "Benten's Curse (Air)",
  "Bishamon's Curse (Water)",
  "Bitter Betrothal (Water)",
  "Blackmailed by [Character Name] (Air)",
  "Blindness (Water)",
  "Blood Feud (Water)",
  "Bluntness (Air)",
  "Bullheaded (Earth)",
  "Clumsiness (Fire)",
  "Cognitive Lapses (Void)",
  "Cursed Lineage (Void)",
  "Daikoku's Curse (Water)",
  "Damaged Heart or Organ (Earth)",
  "Deafness (Air)",
  "Debt to [Person or Organization] (Water)",
  "Demon Wound (Earth)",
  "Despised in [City] (Water)",
  "Discomfiting Countenance (Air)",
  "Disdain for Compassion (Water)",
  "Disdain for Courage (Fire)",
  "Disdain for Courtesy (Air)",
  "Disdain for Duty and Loyalty (Earth)",
  "Disdain for Honor (Void)",
  "Disdain for Righteousness (Void)",
  "Disdain for Sincerity (Void)",
  "Ebisu's Curse (Earth)",
  "Elemental Deficiency (Air)",
  "Elemental Deficiency (Earth)",
  "Elemental Deficiency (Fire)",
  "Elemental Deficiency (Water)",
  "Encompassing Duty (Earth)",
  "Fractured Spine (Earth)",
  "Fukurokujin's Curse (Fire)",
  "Gaijin Name, Culture or Appearance (Fire)",
  "Haunting (Void)",
  "Horrible Scar (Air)",
  "Hunted By Chikusho-do (Earth)",
  "Incurable Illness (Earth)",
  "Jurojin's Curse (Void)",
  "Kisshoten's Curse (Water)",
  "Lackluster (Fire)",
  "Lost Arm or Lost Hand (Fire)",
  "Lost Eye (Water)",
  "Lost Fingers (Fire)",
  "Lost Head (Air)",
  "Lost Leg (Water)",
  "Lost Memories (Void)",
  "Lost Name (Void)",
  "Maimed Arm (Fire)",
  "Maimed Visage (Air)",
  "Many Mouths (Earth)",
  "Momoku (Void)",
  "Muteness (Air)",
  "Nerve Damage (Air)",
  "Overconfidence in Charm (Water)",
  "Overconfidence in Creativity (Fire)",
  "Overconfidence in Toughness (Earth)",
  "Overconfidence in Spiritual Sensitivity (Void)",
  "Overconfidence in Subtlety (Air)",
  "Pursued by [Individual or Group] (Water)",
  "Sailor's Tongue (Air)",
  "Scorn of [One Group] (Water)",
  "Shadowlands Taint (Air)",
  "Shadowlands Taint (Earth)",
  "Shadowlands Taint (Fire)",
  "Shadowlands Taint (Void)",
  "Shadowlands Taint (Water)",
  "Shadowlands Taint [Blasphemous Appetites] (Water)",
  "Shadowlands Taint [Distorted Limbs] (Earth)",
  "Shadowlands Taint [Kansen Whispers] (Air)",
  "Shadowlands Taint [One With the Darkness] (Void)",
  "Shadowlands Taint [Unnatural Skin] (Fire)",
  "Skepticism (Void)",
  "Stained Lineage (Void)",
  "Sworn Enemy (Earth)",
  "Unsavory Past (Water)",
  "Whispers of Cruelty (Earth)",
  "Whispers of Doom (Void)",
  "Whispers of Failure (Fire)",
  "Whispers of Poverty (Water)",
  "Whispers of Treachery (Air)",
];

export const anxieties = [
  "Accustomed to Luxury (Earth)",
  "Addiction (Earth)",
  "Battle Trauma (Fire)",
  "Belligerent (Earth)",
  "Braggart (Water)",
  "Claustrophobia (Water)",
  "Coming Storm (Void)",
  "Conspiracy (Earth)",
  "Cynism (Fire)",
  "Dark Secret (Void)",
  "Delusions of Grandeur (Void)",
  "Eyes of Many (Air)",
  "Failure (Water)",
  "Fallen Ancestors (Void)",
  "False Identity (Air)",
  "Fear of Death (Earth)",
  "Fear of Stagnation (Earth)",
  "Ferocity (Air)",
  "Impatience (Earth)",
  "Intolerance (Water)",
  "Irrepressible Flirtation (Earth)",
  "Isolation (Earth)",
  "Jealousy (Air)",
  "Loathing for Peasants (Water)",
  "Loneliness (Void)",
  "Materialism (Void)",
  "Meekness (Fire)",
  "Obtuse (Air)",
  "Omen of Bad Luck (Water)",
  "Painful Honesty (Air)",
  "Paranoia (Water)",
  "Perfectionism (Water)",
  "Phobia (Water)",
  "Reformed Maho-Tsukai (Water)",
  "Softheartedness (Fire)",
  "Superstition (Void)",
  "Uncleanliness (Air)",
  "Vanity (Void)",
  "Web of Lies (Air)",
];
