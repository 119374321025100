// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".VfGrpypktE77Hb6ZQPC3 {\n  display: flex;\n  justify-content: center;\n}\n.Y6T7ZElL49D5xe9xMyhj {\n  min-width: 180px;\n  margin-bottom: 12px;\n}\n", "",{"version":3,"sources":["webpack://./src/features/unified/Selector.module.less"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,uBAAA;AACF;AAEA;EACE,gBAAA;EACA,mBAAA;AAAF","sourcesContent":[".container {\n  display: flex;\n  justify-content: center;\n}\n\n.selector {\n  min-width: 180px;\n  margin-bottom: 12px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "VfGrpypktE77Hb6ZQPC3",
	"selector": "Y6T7ZElL49D5xe9xMyhj"
};
export default ___CSS_LOADER_EXPORT___;
