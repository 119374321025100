// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".sN7ObrrdyVZJzpVIi7yJ {\n  background-color: #efefe7;\n  padding: 24px;\n}\n.sN7ObrrdyVZJzpVIi7yJ > * {\n  margin-bottom: 24px;\n}\n.sN7ObrrdyVZJzpVIi7yJ > *:last-child {\n  margin-bottom: 48px;\n}\n", "",{"version":3,"sources":["webpack://./src/features/roller/IdentifiedRoll.module.less"],"names":[],"mappings":"AAEA;EACE,yBAAA;EACA,aAAA;AADF;AAGE;EACE,mBAAA;AADJ;AAGI;EACE,mBAAA;AADN","sourcesContent":["@import (reference) \"../../common.less\";\n\n.ongoing {\n  background-color: @default-background-color;\n  padding: 24px;\n\n  & > * {\n    margin-bottom: 24px;\n\n    &:last-child {\n      margin-bottom: 48px;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ongoing": "sN7ObrrdyVZJzpVIi7yJ"
};
export default ___CSS_LOADER_EXPORT___;
