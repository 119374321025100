// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".aZqhEFyJB2eSJO4qSCyp {\n  padding-top: 0;\n}\n.aZqhEFyJB2eSJO4qSCyp > ul.ant-menu {\n  border-radius: 0;\n}\n.rlZs64ozAT04m8zr6Osz.ant-menu-item {\n  margin-left: auto;\n}\n.r0KhFM6jB5nkJBThsCqG a {\n  display: flex;\n  align-items: center;\n  height: 100%;\n}\n", "",{"version":3,"sources":["webpack://./src/features/navigation/Menu.module.less"],"names":[],"mappings":"AAAA;EACE,cAAA;AACF;AACE;EACE,gBAAA;AACJ;AAGA;EACE,iBAAA;AADF;AAIA;EAEI,aAAA;EACA,mBAAA;EACA,YAAA;AAHJ","sourcesContent":[".sub-menu {\n  padding-top: 0;\n\n  & > ul:global(.ant-menu) {\n    border-radius: 0;\n  }\n}\n\n.push-to-right:global(.ant-menu-item) {\n  margin-left: auto;\n}\n\n.logo {\n  a {\n    display: flex;\n    align-items: center;\n    height: 100%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"sub-menu": "aZqhEFyJB2eSJO4qSCyp",
	"push-to-right": "rlZs64ozAT04m8zr6Osz",
	"logo": "r0KhFM6jB5nkJBThsCqG"
};
export default ___CSS_LOADER_EXPORT___;
