// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".iSL0jp3lx_2jJSmX1oaQ {\n  display: flex;\n  flex-wrap: wrap;\n}\n.iSL0jp3lx_2jJSmX1oaQ ul {\n  display: flex;\n}\n.iSL0jp3lx_2jJSmX1oaQ ul li {\n  padding: 0;\n  margin-right: 4px;\n}\n.Tz9gKtVMxyzhNakkpOSp {\n  min-height: 44px;\n  margin-bottom: 1em;\n  text-align: center;\n}\n.mo3GMqu2pnjIRH7xym6w {\n  margin-bottom: 24px;\n}\n.mo3GMqu2pnjIRH7xym6w .ant-form-item {\n  margin-bottom: 12px;\n}\n.mo3GMqu2pnjIRH7xym6w .ant-collapse-content-box {\n  padding-top: 8px;\n  padding-bottom: 8px;\n}\n.F8WSuO26WdZ777wTy1Jv {\n  display: flex;\n}\n.F8WSuO26WdZ777wTy1Jv > * {\n  margin-right: 24px;\n}\n", "",{"version":3,"sources":["webpack://./src/features/d10/D10Roller.module.less"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,eAAA;AACF;AAHA;EAII,aAAA;AAEJ;AANA;EAOM,UAAA;EACA,iBAAA;AAEN;AAGA;EACE,gBAAA;EACA,kBAAA;EACA,kBAAA;AADF;AAIA;EACE,mBAAA;AAFF;AACA;EAII,mBAAA;AAFJ;AAFA;EAQI,gBAAA;EACA,mBAAA;AAHJ;AAOA;EACE,aAAA;AALF;AAOE;EACE,kBAAA;AALJ","sourcesContent":[".syntax {\n  display: flex;\n  flex-wrap: wrap;\n  ul {\n    display: flex;\n\n    li {\n      padding: 0;\n      margin-right: 4px;\n    }\n  }\n}\n\n.placeholder {\n  min-height: 44px;\n  margin-bottom: 1em;\n  text-align: center;\n}\n\n.extra-options {\n  margin-bottom: 24px;\n\n  :global(.ant-form-item) {\n    margin-bottom: 12px;\n  }\n\n  :global(.ant-collapse-content-box) {\n    padding-top: 8px;\n    padding-bottom: 8px;\n  }\n}\n\n.inlined {\n  display: flex;\n\n  & > * {\n    margin-right: 24px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"syntax": "iSL0jp3lx_2jJSmX1oaQ",
	"placeholder": "Tz9gKtVMxyzhNakkpOSp",
	"extra-options": "mo3GMqu2pnjIRH7xym6w",
	"inlined": "F8WSuO26WdZ777wTy1Jv"
};
export default ___CSS_LOADER_EXPORT___;
