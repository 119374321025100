// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".i5XgKRIKVjJkoysHYSD8 .ant-collapse-content {\n  background-color: #efefe7;\n}\n", "",{"version":3,"sources":["webpack://./src/features/roller/Layout.module.less"],"names":[],"mappings":"AAEA;EAEI,yBAAA;AAFJ","sourcesContent":["@import (reference) \"common.less\";\n\n.layout {\n  :global(.ant-collapse-content) {\n    background-color: @default-background-color;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"layout": "i5XgKRIKVjJkoysHYSD8"
};
export default ___CSS_LOADER_EXPORT___;
