// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".RP8RtIpQRqPfs5EgpxUz {\n  text-align: center;\n}\n", "",{"version":3,"sources":["webpack://./src/features/cyberpunk/Roll.module.less"],"names":[],"mappings":"AAAA;EACE,kBAAA;AACF","sourcesContent":[".result {\n  text-align: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"result": "RP8RtIpQRqPfs5EgpxUz"
};
export default ___CSS_LOADER_EXPORT___;
