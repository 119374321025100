// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".wKW3zelbhRJP2_LFM_PM {\n  background-color: #87e8de;\n}\n._4A3eFeAMu4jCqIhbNNQ:hover {\n  background-color: #5cdbd3;\n}\n", "",{"version":3,"sources":["webpack://./src/features/roller/reroll/RerollDiceBox.module.less"],"names":[],"mappings":"AAEA;EACE,yBAAA;AADF;AAKE;EACE,yBAAA;AAHJ","sourcesContent":["@import (reference) \"../../../common.less\";\n\n.selected {\n  background-color: @reroll-die-color;\n}\n\n.selectable {\n  &:hover {\n    background-color: #5cdbd3;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"selected": "wKW3zelbhRJP2_LFM_PM",
	"selectable": "_4A3eFeAMu4jCqIhbNNQ"
};
export default ___CSS_LOADER_EXPORT___;
